import { ref } from 'vue'
import apiHandler from '@/use/apiHandler'
import loadingHandler from '@/use/loadingHandler'
import formHandler from '@/use/formHandler'
import selectValues from '@/use/selects/values'

export default () => {
  const useApi = apiHandler()
  const loadHandler = loadingHandler()
  const editForm = formHandler()

  let contract = ref({})
  let showAdd = ref(false)
  let showEdit = ref(false)
  let showAddNodes = ref(false)
  let unassigned_nodes = ref([])
  let unassigned_nodes_selected = ref([])

  function getDetails(payload) {
    loadHandler.setLoadingState('get_details', true)
    useApi.request_api('get', 'v1', 'contracts/' + payload.object_id).then((response) => {
      const data = response.data
      contract.value = data
      if (response.data.nodes.length !== 0) {
        let node_ids = response.data.nodes.map((node) => node.object_id)
        useApi
          .request_api(
            'get',
            'v1',
            'nodes/?fields=description,discount,type,place,contracts,residential,medium,provider_identifier,object_id,geometry&' +
              node_ids.map((id) => 'ids=' + id).join('&'),
          )
          .then((response) => {
            contract.value.nodes = response.data

            contract.value.nodes.forEach((node) => {
              node.type_name = node.type?.name
            })
          })

        getUnassignedCluster()

        loadHandler.setLoadingState('get_details', false)
      } else {
        contract.value.nodes = []
        getUnassignedCluster()
        loadHandler.setLoadingState('get_details', false)
      }
    })
  }

  const contractFields = ref([
    {
      label: 'Code',
      key: 'code',
      type: 'string',
      required: true,
      maxLength: 100,
    },
    {
      label: 'Type',
      key: 'type',
      type: 'string',
      component: 'pillMainType',
      disabled: true,
      translate_value: true,
    },
    {
      label: 'Provider',
      key: 'provider',
      type: 'string',
      required: true,
      maxLength: 100,
    },
    {
      label: 'Start date',
      key: 'start_date',
      type: 'date',
      filter: 'dateFromEpochDay',
    },
    {
      label: 'End date',
      key: 'end_date',
      type: 'date',
      filter: 'dateFromEpochDay',
    },
    {
      label: 'Peak rate',
      key: 'electricity_peak_rate',
      type: 'number',
      filter: 'toCurrencyNoRound',
      dependent_show_values: ['Electricity'],
      dependent_show_field_key: 'type',
      dependent_show_field_type: 'select',
      required: true,
    },
    {
      label: 'Off-peak rate',
      key: 'electricity_offpeak_rate',
      type: 'number',
      filter: 'toCurrencyNoRound',
      dependent_show_values: ['Electricity'],
      dependent_show_field_key: 'type',
      dependent_show_field_type: 'select',
      required: true,
    },
    {
      label: 'Peak return rate',
      key: 'electricity_peak_return_rate',
      type: 'number',
      filter: 'toCurrencyNoRound',
      dependent_show_values: ['Electricity'],
      dependent_show_field_key: 'type',
      dependent_show_field_type: 'select',
      required: true,
    },
    {
      label: 'Off-peak return rate',
      key: 'electricity_offpeak_return_rate',
      type: 'number',
      filter: 'toCurrencyNoRound',
      dependent_show_values: ['Electricity'],
      dependent_show_field_key: 'type',
      dependent_show_field_type: 'select',
      required: true,
    },
    {
      label: 'Off-peak hours',
      key: 'off_peak_hours',
      type: 'select',
      options: selectValues.offPeakHours,
      table: false,
      dependent_show_values: ['Electricity'],
      dependent_show_field_key: 'type',
      dependent_show_field_type: 'select',
      required: true,
    },
    {
      label: 'Gas rate',
      key: 'gas_rate',
      type: 'number',
      filter: 'toCurrencyNoRound',
      dependent_show_values: ['Gas'],
      dependent_show_field_key: 'type',
      dependent_show_field_type: 'select',
      required: true,
    },
    {
      label: 'Water rate',
      key: 'water_rate',
      type: 'number',
      filter: 'toCurrencyNoRound',
      dependent_show_values: ['Water'],
      dependent_show_field_key: 'type',
      dependent_show_field_type: 'select',
      required: true,
    },
    {
      label: 'Heat rate',
      key: 'heat_rate',
      type: 'number',
      filter: 'toCurrencyNoRound',
      dependent_show_values: ['Heat'],
      dependent_show_field_key: 'type',
      dependent_show_field_type: 'select',
      required: true,
    },
    {
      label: 'Heat cost rate',
      key: 'heatcost_rate',
      type: 'number',
      filter: 'toCurrencyNoRound',
      dependent_show_values: ['HeatCost'],
      dependent_show_field_key: 'type',
      dependent_show_field_type: 'select',
      required: true,
    },
  ])

  const nodeFields = ref([
    {
      label: 'Description',
      key: 'description',
      type: 'string',
      searchable: true,
    },
    {
      label: 'Identification',
      key: 'provider_identifier',
      type: 'string',
      add: false,
      edit: false,
      searchable: true,
    },
    {
      label: 'Medium',
      key: 'medium',
      type: 'string',
      component: 'pillMedium',
      searchable: true,
    },
    {
      label: 'Type',
      key: 'type_name',
      type: 'string',
      searchable: true,
    },
    {
      label: 'Size',
      key: 'size',
      type: 'string',
      table: false,
    },
    {
      label: 'Zip',
      key: 'zip',
      type: 'string',
      table: false,
    },
    {
      label: 'Address',
      key: 'address',
      type: 'string',
      searchable: true,
    },
    {
      label: 'Place',
      key: 'place',
      type: 'string',
      searchable: true,
    },
    // {
    //   label: 'Residential',
    //   key: 'residential',
    //   type: 'string',
    // },
    // {
    //   label: 'Discount',
    //   key: 'discount',
    //   type: 'string',
    // },
  ])

  function openEdit() {
    editForm.create({
      type: 'edit',
      fields: contractFields.value,
      data: contract.value,
    })
    showEdit.value = true
  }

  function closeEdit() {
    showEdit.value = false
  }

  function edit() {
    loadHandler.setLoadingState('edit_contract', true)
    const { object_id, ...payload } = editForm.getData()

    useApi.request_api('patch', 'v1', `contracts/${object_id}`, payload).then(() => {
      const getDetailsPayload = {
        object_id,
      }

      getDetails(getDetailsPayload)
      closeEdit()
      loadHandler.setLoadingState('edit_contract', false)
    })
  }

  function openAddNodes() {
    showAddNodes.value = true
  }

  function closeAddNodes() {
    showAddNodes.value = false
  }

  function getUnassignedCluster() {
    loadHandler.setLoadingState('get_unassigned_contract', true)
    useApi
      .request_api(
        'get',
        'v1',
        'nodes/unassigned/contracts?start_date=' +
          contract.value.start_date +
          '&end_date=' +
          contract.value.end_date +
          '&medium=' +
          contract.value.type,
      )
      .then((response) => {
        const data = response.data
        if (data.length === 0) {
          unassigned_nodes.value = []
          loadHandler.setLoadingState('get_unassigned_contract', false)
          return
        }
        let node_ids_paramaters = response.data.map((node) => 'ids=' + node).join('&')
        useApi
          .request_api(
            'get',
            'v1',
            'nodes/?fields=object_id,description,medium,provider_identifier,residential,address,place,discount,type&' +
              node_ids_paramaters,
          )
          .then((response) => {
            unassigned_nodes.value = response.data
            unassigned_nodes.value.forEach((node) => {
              node.type_name = node.type?.name
            })
            loadHandler.setLoadingState('get_unassigned_contract', false)
          })
      })
  }

  function assignNodes() {
    const payload = {
      ids: Object.keys(unassigned_nodes_selected.value),
    }

    loadHandler.setLoadingState('assign_to_cluster', true)
    useApi.request_api('patch', 'v1', 'contracts/' + contract.value.object_id + '/nodes/add', payload).then(() => {
      closeAddNodes()
      loadHandler.setLoadingState('assign_to_cluster', false)

      const getDetailsPayload = {
        object_id: contract.value.object_id,
      }

      getDetails(getDetailsPayload)
    })
  }

  function setSelectedUnassignedNodes(selection) {
    unassigned_nodes_selected.value = selection
  }

  function unAssignNode(nodeId) {
    const payload = { ids: [nodeId] }

    loadHandler.setLoadingState('unassign_node', true)
    useApi.request_api('patch', 'v1', 'contracts/' + contract.value.object_id + '/nodes/remove', payload).then(() => {
      loadHandler.setLoadingState('unassign_node', false)
      const getDetailsPayload = {
        object_id: contract.value.object_id,
      }

      getDetails(getDetailsPayload)
    })
  }

  return {
    contract,
    loadHandler,
    contractFields,
    nodeFields,
    showEdit,
    showAdd,
    getDetails,
    editForm,
    openEdit,
    closeEdit,
    edit,
    openAddNodes,
    closeAddNodes,
    showAddNodes,
    getUnassignedCluster,
    assignNodes,
    setSelectedUnassignedNodes,
    unAssignNode,
    unassigned_nodes,
    unassigned_nodes_selected,
  }
}
